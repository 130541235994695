import React from 'react';
import './App.css';
import Home from './components/UpdatePage/newHome.js';
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Projects from './components/Projects';
import NavBar from './components/UpdatePage/newNavBar';
import NotFound from './components/NotFound';
// import UploadPhoto from './components/UploadPhoto';
import newProcess from './components/UpdatePage/newProcess';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/projects" component={Projects} exact />
        <Route path="/process" component={newProcess} exact />
        {/* <Route path="/addphotos" component={UploadPhoto} exact /> */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
