import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import esgcLogo from '../../images/esgc.svg'
import Modal from 'react-modal';
import Axios from "axios";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


Modal.defaultStyles.overlay.backgroundColor = "rgb(149 160 171 / 86%)";

const customStyles = {
  content: {
    margin: "70px",
    borderRadius: '10px',
    zIndex: "3"
  }
};

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)

export default class NavBar extends Component {
  colapse = React.createRef();
  state = {
    isOpen: false,
    userName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    inputState: "",
    zip: "",
    budget: "",
    startDate: "",
    description: "",
    errors: []
  }
  openModal = () => {
    this.setState(() => ({
      isOpen: true
    }))
  }
  closeModal = () => {
    this.setState(() => ({
      isOpen: false
    }))
  }
  closeBurger = () => {
    // console.log(this.colapse.current);
    setTimeout(() => {
      this.colapse.current.classList.remove("show")
    }, 500);
  }
  hasError = key => {
    return this.state.errors.indexOf(key) !== -1;
  }
  handleChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    this.setState(() => ({
      [name]: val
    }))
  }
  handleSubmit = async (e) => {
    // console.log(this.state);
    e.preventDefault();
    let errors = [];
    if (this.state.userName.length < 2) errors.push("userName");

    const emailExpression = /\S+@\S+/;
    let validEmail = emailExpression.test(String(this.state.email).toLowerCase());
    if (!validEmail) errors.push("email");

    const phoneExpression = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    let validPhone = phoneExpression.test(this.state.phone);
    if (!validPhone) errors.push("phone");

    if (this.state.address.length < 5) errors.push("address");
    if (!this.state.city.length) errors.push("city");
    if (!this.state.inputState.length) errors.push("inputState");

    const zipExpression = /^\d{5}(?:[-\s]\d{4})?$/;
    let validZip = zipExpression.test(this.state.zip);
    if (!validZip) errors.push("zip");

    // if (!this.state.budget) errors.push("budget");

    if (!this.state.description) errors.push("description");

    this.setState((state) => ({
      errors
    }));

    if (errors.length > 0) {
      return false;
    } else {
      try {
        const { data } = await Axios.post("/email", { ...this.state });
        // console.log(data);
        this.setState(() => ({
          isOpen: false,
          userName: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          inputState: "",
          zip: "",
          // budget: "",
          startDate: "",
          description: "",
          errors: []
        }))
      } catch (error) {
        // console.log(error);
        return;
      }
    }


  }

  render() {
    return (
      <div>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/"
            style={{ fontFamily: 'Girassol cursive', letterSpacing: "1px" }}
          >
            EAST SAC GENERAL CONTRACTING
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={this.colapse}>
            <ul className="navbar-nav">
              <li className="nav-item active" onClick={this.closeBurger}>
                <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item" onClick={this.closeBurger}>
                <Link className="nav-link" to="/process">Process</Link>
              </li>
              <li className="nav-item" onClick={this.closeBurger}>
                <Link className="nav-link" to="/projects">Projects</Link>
              </li>
              <li className="nav-item" onClick={this.closeBurger}>
                <p onClick={this.openModal} id="contact-btn" >Contact Us</p>
                <Modal
                  isOpen={this.state.isOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <button onClick={this.closeModal} className="btn btn-danger" id="close-modal"
                  >X</button>
                  <div className="container-fluid" id="modal-container">

                    <h2 >READY TO START BUILDING? LET'S TALK.</h2>
                    <form onSubmit={this.handleSubmit}
                      style={{ width: "100%", marginTop: "65px" }}
                      className="needs-validation"
                      noValidate
                    >
                      <div className="mb-3">
                        <label htmlFor="userNameInput" className="form-label">Name</label>
                        <input type="text" name="userName" id="userNameInput" className={this.hasError("userName")
                          ? "form-control is-invalid"
                          : "form-control"} aria-describedby="userNameHelp"
                          value={this.state.userName} onChange={this.handleChange}
                          required autoComplete='false'
                        />
                        <div
                          className={
                            this.hasError("userName") ? "inline-errormsg" : "hidden"
                          }
                        >
                          Please enter name
                          </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="inputEmail1" className="form-label">Email address</label>
                        <input type="email" name="email" className={this.hasError("email")
                          ? "form-control is-invalid"
                          : "form-control"} id="exampleInputEmail1" aria-describedby="emailHelp"
                          value={this.state.email} onChange={this.handleChange}
                          required
                        />
                        <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                        <div
                          className={this.hasError("email") ? "inline-errormsg" : "hidden"}
                        >
                          Email is invalid or missing
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="inputPhone" className="form-label">Phone</label>
                        <MaskedInput
                          // Phone number mask
                          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          name="phone"
                          className={this.hasError("phone")
                            ? "form-control is-invalid"
                            : "form-control"}
                          placeholder="Enter a phone number"
                          guide={false}
                          id="inputPhone"
                          onBlur={() => { }}
                          onChange={this.handleChange}
                          value={this.state.phone}
                          required
                        />
                        {/* <label htmlFor="inputPhone" className="form-label">Phone</label>
                        <input type="tel" name="phone" className={this.hasError("phone")
                          ? "form-control is-invalid"
                          : "form-control"} aria-describedby="emailHelp"
                          value={this.state.phone} onChange={this.handleChange} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          required
                        /> */}
                        <small>Format: 123-456-7890</small>
                        <div
                          className={
                            this.hasError("phone") ? "inline-errormsg" : "hidden"
                          }
                        >
                          Please enter valid phone number
                          </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="inputAddress" className="form-label">Project Address</label>
                          <input type="text" onChange={this.handleChange} value={this.state.address} name="address" className={this.hasError("address")
                            ? "form-control is-invalid"
                            : "form-control"} id="inputAddress" placeholder="1234 Main St" required />
                          <div
                            className={
                              this.hasError("address") ? "inline-errormsg" : "hidden"
                            }
                          >
                            Please enter a valid address
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="inputCity" className="form-label"></label>
                          <input type="text" placeholder="City" onChange={this.handleChange} value={this.state.city} name="city" className={this.hasError("city")
                            ? "form-control is-invalid"
                            : "form-control"} id="inputCity" required />
                          <div
                            className={
                              this.hasError("city") ? "inline-errormsg" : "hidden"
                            }
                          >
                            Please enter a valid city
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="inputState" className="form-label"></label>
                          <input type="text" placeholder="State" onChange={this.handleChange} value={this.state.inputState} name="inputState" className={this.hasError("inputState")
                            ? "form-control is-invalid"
                            : "form-control"} id="inputState" required />
                          <div
                            className={
                              this.hasError("inputState") ? "inline-errormsg" : "hidden"
                            }
                          >
                            Please enter a valid state
                          </div>
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="inputZip" className="form-label"></label>
                          <input type="text" placeholder="Postal Code" onChange={this.handleChange} value={this.state.zip} name="zip" className={this.hasError("zip")
                            ? "form-control is-invalid"
                            : "form-control"} id="inputZip" required />
                          <div
                            className={
                              this.hasError("zip") ? "inline-errormsg" : "hidden"
                            }
                          >
                            Please enter a valid zip/postal code
                          </div>
                        </div>

                      </div>

                      {/* <div className="mb-3">
                        <label htmlFor="inputBudget" className="form-label">Estimated Project Budget</label>
                        <MaskedInput mask={currencyMask}
                          className={this.hasError("budget")
                            ? "form-control is-invalid"
                            : "form-control"}
                          name="budget"
                          placeholder="$0.00"
                          onChange={this.handleChange}
                          value={this.state.budget}
                          id="inputBudget"
                        />
                        <div
                          className={
                            this.hasError("budget") ? "inline-errormsg" : "hidden"
                          }
                        >
                          Please enter valid budget
                          </div> */}
                      {/* <label htmlFor="inputBudget" className="form-label">Estimated Project Budget</label>
                        <input type="number" onChange={this.handleChange} value={this.state.budget} name="budget" className="form-control" id="inputBudget" /> */}
                      {/* </div> */}

                      <div className="mb-3">
                        <label htmlFor="inputStartDate" className="form-label">Desired Start Date</label>
                        <input type="text" onChange={this.handleChange} value={this.state.startDate} name="startDate" className="form-control" id="inputStartDate" />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="inputProjectDesc" className="form-label">Project Description</label>
                        <textarea type="text" onChange={this.handleChange} value={this.state.description} name="description" className={this.hasError("description")
                          ? "form-control is-invalid"
                          : "form-control"} id="inputProjectDesc" required />
                        <div
                          className={
                            this.hasError("description") ? "inline-errormsg" : "hidden"
                          }
                        >
                          Please enter a small description
                        </div>
                      </div>


                      <button className="btn btn-primary "
                        style={{ textAlign: "center", width: "100%", borderRadius: "20px" }}
                      >Submit</button>
                    </form>
                  </div>
                </Modal>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}
