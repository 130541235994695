import React, { Component } from 'react';
import Contact from '../UpdatePage/newContact';
import About from '../UpdatePage/newAbout';
import Slider from "react-slick";
import sliderImgs from "../../utils/imgsArray"
import esgcLogo from '../../images/esgc.svg'


class Home extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      autoplay: true,
      speed: 4000,
      fade: true,
      adaptiveHeight: true,
      arrows: false
    }
    return (
      <div className="conatainer">
        {/* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */}
        {/* <h1
          id="main-header"
        >East Sac
        <br />
        General Contracting
        </h1> */}
        <div className="container-fluid"
          style={{
            padding: "0px",
            margin: "0px"
          }}
        >
          <div
            id="main-header"
          >
            <img

              src={esgcLogo} alt="" />
          </div>
          <Slider {...settings} >
            {sliderImgs.map((image, i) => {
              // console.log(image, "IMG");
              return (
                <div key={i}>
                  <div className="container-fluid carrosal"
                    style={{
                      backgroundImage: `linear-gradient(to right bottom, rgb(15, 32, 39, 0.2), rgb(32, 58, 67, 0.1), rgb(44, 83, 100, 0.1)), url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      height: "90vh"
                    }}
                  >
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>

        <About />
        {/* <Testimonials /> */}
        <Contact />
      </div>
    )
  }
}


export default Home;