import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div id="new-home-contact" className="container-fluid" style={{ backgroundColor: "#343a40" }}>
        <div className="container">
          <h3
          >LET US BUILD YOUR VISION</h3>
          <div className="container text-white">
            <div className="container">
              <hr style={{
                backgroundColor: "white"
              }} />
            </div>


            <div className="row" style={{ paddingTop: "20px" }}>

              <div className="col-md-6" style={{ height: "100px", textAlign: "center" }}>
                <p>
                  Contact us by phone or email for your next project. You can read our Yelp reviews by clicking on the Yelp icon below.   <br />
                Lic #964297
                </p>
              </div>
              <div className="col-md-6 " id="icon-row">
                <div className="row" style={{ textAlign: "center" }}>

                  <div className="col-6"
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="div-fake-hidden">
                      <a href="https://www.yelp.com/biz/east-sac-general-contracting-sacramento"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <i className="fab fa-yelp fa-5x" style={{ color: "white" }}></i>
                      </a>
                    </div>
                  </div>

                  {/* <div className="col-4 "
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="div-fake-hidden">
                      <a href="mailto:victorsac1@aol.com"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <i className="far fa-envelope fa-5x" style={{ color: "white" }}></i>
                      </a>
                    </div>
                  </div> */}

                  <div className="col-6"
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="div-fake-hidden">
                      <a href="tel:+1-916-812-4202" style={{ color: "white" }}> <i className="fas fa-phone fa-4x" style={{ color: "white" }}></i> <br />
                      (916) 812-4202
                  </a>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Contact;
// <i class="fab fa-yelp"></i>