import React, { Component } from 'react'

export default class newProcess extends Component {
  render() {
    return (
      <div id="the-process" className="container-fluid">
        <div id="advantage" className="conatiner">
          <h1>The East Sac General Contracting
            
            Advantage</h1>
            <hr/>
          <h3>SOLUTIONS DRIVEN COLLABORATION</h3>
          <p>
            Critical to a project’s success is a genuine collaboration between the contractor and the client, along with timely and transparent reporting to the client. This communication is crucial to the process of a project. Letting our customers know of issues before they arise. ESGC team facilitates projects ‘day-to-day’, keeping jobs on track and on schedule by keeping communication flowing so that all parties are informed throughout the lifecycle of the project. ESGC is committed to this collective workstyle to ensure every nuance of the original plan is executed to perfection.
          </p>
        </div>
        <div id="how-we-work" className="container-fluid">
          <h1>How we work</h1>
          <hr style={{ width: "95%"}}/>
          <ul>
            <li>
              <h3>Collaboration</h3>
              <hr />
              <p>Our work style is supportive and collaborative. Throughout the entire project, we work closely with the client to ensure every nuance of the original plan is executed perfectly. Every detail is scrutinized at all phases of construction, beginning with the bid phase, to assure a smooth and efficient project.</p>
            </li>
            {/* <li>
              <h3>Personal Service</h3>
              <hr />
              <p>We work with our clients with there buget, taylor to there needs throught the whole process.  Our timeline keeps our clients informed at each phase of the project lifecycle and is extremely useful to the client to gauge our progress at a glance.</p>
            </li> */}
            <li>
              <h3>Day to Day</h3>
              <hr />
              <p>The ESGC team keeps jobs on track and on schedule. Daily proactive communication with our clients alleviates unnecessary delays and keeps things moving efficiently. Our timeline keeps our clients informed at each phase of the project lifecycle and is extremely useful to the client to gauge our progress at a glance.</p>
            </li>
            <li>
              <h3>Post Construction</h3>
              <hr />
              <p>Our final walk-through is thorough and meticulous. We provide a checklist of items to be reviewed, ensuring everything is working properly. Trim and finishes are carefully inspected. Anything requiring touch-up or attention is added to a ‘todo list‘ and corrected before we hand the keys back over to the client.</p>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
