import React, { Component } from "react";

export default class newAbout extends Component {
  render() {
    return (
      <div
        id="newAbout"
        className="container"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <h2> EAST SAC GENERAL CONTRACTING</h2>
        <hr style={{ border: "1px solid black" }} />
        <div className="container">
          <h3>GENERAL CONTRACTOR | REMODEL </h3>
          <p>
            Established in 2006, East Sac General Contracting is a small family
            owned business. We pride ourselves on great work ethics and keen
            attention to details. Helping our clients bring their unique visions
            to reality is our number one goal.
          </p>
        </div>
      </div>
    );
  }
}
