import React, { Component } from 'react'
import Gallery from './Gallery';

function importAll(r) {
  return r.keys().map(r);
}

const Adelenasimages = importAll(require.context('../images/adelenas', false, /\.(png|jpe?g|svg)$/));
const Christiansimages = importAll(require.context('../images/christans', false, /\.(png|jpe?g|svg)$/));
const Johnimages = importAll(require.context('../images/johnathan', false, /\.(png|jpe?g|svg)$/));
const Deckimages = importAll(require.context('../images/downtownDeck', false, /\.(png|jpe?g|svg)$/));
const Keithimages = importAll(require.context('../images/keiths', false, /\.(png|jpe?g|svg)$/));
const Ronsimages = importAll(require.context('../images/ronsBarn', false, /\.(png|jpe?g|svg)$/));
const Vicimages = importAll(require.context('../images/vics', false, /\.(png|jpe?g|svg)$/));
const Vicbackimages = importAll(require.context('../images/vicsBack', false, /\.(png|jpe?g|svg)$/));
const Wooimages = importAll(require.context('../images/woosDads', false, /\.(png|jpe?g|svg)$/));
const LivingRoomimages = importAll(require.context('../images/livingRoom', false, /\.(png|jpe?g|svg)$/));
const KeithOakimages = importAll(require.context('../images/keithoakpark', false, /\.(png|jpe?g|svg)$/));
const YardCrashersimages = importAll(require.context('../images/yardCrashers', false, /\.(png|jpe?g|svg)$/));

// console.log(images);
export default class Projects extends Component {
  render() {
    return (
      <div id="projects" style={{ paddingTop: "70px", paddingBottom: "70px" }} className="container">
        <h1 >Past Projects</h1>
        <p className="img-text"
          style={{
            textAlign: "center",
            fontFamily: 'Girassol cursive'
          }}
        >To view more pictures click on a photo</p>
        <hr />

        <div className="container">
          {/* card-columns */}
          <div className="row">
            {/* can now add text value to display project name */}
            <Gallery photos={Keithimages}
            // text={"wow this is cool"}
            />
            <Gallery photos={Christiansimages} />
            <Gallery photos={Vicimages} />
            <Gallery photos={KeithOakimages} />
            <Gallery photos={Vicbackimages} />
            <Gallery photos={Ronsimages} />
            <Gallery photos={Deckimages} />
            <Gallery photos={Johnimages} />
            <Gallery photos={Wooimages} />
            <Gallery photos={LivingRoomimages} />
            <Gallery photos={YardCrashersimages} />
            <Gallery photos={Adelenasimages} />
          </div>

        </div>
      </div>
    )
  }
}