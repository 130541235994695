import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { hvr } from 'hover.css'

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      photos: [...this.props.photos]
    };
  }

  render() {
    const { photoIndex, isOpen, photos } = this.state;

    return (
      <div className="col-sm-12 col-md col-lg-4" style={{ textAlign: "center" }}>
        {/* <button type="button" onClick={() => this.setState({ isOpen: true })}>
          Open Lightbox
        </button> */}
        <React.Fragment >
          <img
            id="img-gal"
            className="hvr-glow hvr-float-shadow"
            style={{ width: "300px", marginTop: "10px" }}
            src={photos[0]} alt="" onClick={() => this.setState({ isOpen: true })}
          />
        </React.Fragment>
        {isOpen && (
          <Lightbox
            mainSrc={photos[photoIndex]}
            nextSrc={photos[(photoIndex + 1) % photos.length]}
            prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + photos.length - 1) % photos.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % photos.length,
              })
            }
            imagePadding={30}
            discourageDownloads={false}
          />
        )}
      </div>
    );
  }
}