import React from 'react'

export default function NotFound() {
  return (
    <div className="container" style={{ textAlign: "center", paddingTop: "300px" }}>
      <h1
        style={{
          fontFamily: 'Girassol cursive', fontWeight: "500",
          letterSpacing: "2px",
        }}
      >404 Page Not Found. Sorry</h1>
    </div>
  )
}
